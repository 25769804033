import React from "react";
import LpDemo from "../../../page-components/LpDemo";

export default function DemoPageSideKick() {
  return (
    <LpDemo
      leadType="lead-gen"
      formSubHeader="Save hundreds per month with our leading cash discount program that enables you to accept nearly all forms of payment while offsetting card transaction fees."
      formId="1be4eafc-bbef-4ddd-a51f-20f4dce5b7ef"
      useShortForm
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      withLineOfBusinessOption
      toggleVisibilityMap={[
        {
          changeOnKey: "industry",
          changeOnValue: "Other",
          togglesItem: "business_type",
        },
      ]}
    />
  );
}
